import React, { forwardRef, useState, useMemo } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { de } from 'date-fns/locale';
import { Label } from "./Label";
import moment from "moment";
import styles from "./Date.module.scss";
import 'react-datepicker/dist/react-datepicker.css';

interface IDateLabelProps {
    onChange?: (value: Date|null) => void;
    value: Date|null;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    required?: boolean;
    placeholderText?: string;
    // Time selection properties
    showTimeSelect?: boolean;
    timeFormat?: string;
    timeIntervals?: number;
    dateFormat?: string;
    // Default time properties
    defaultHour?: number;
    defaultMinute?: number;
    // Time range constraints
    minTime?: Date;
    maxTime?: Date;
}

function convertUTCToLocalDate(date?: Date|null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
}

function convertLocalToUTCDate(date?: Date|null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}

export function PDS(v?: string): Date | null {
    return v ? moment(v).toDate() : null;
}

export function     FD(v?: string|Date): string {
    return moment(v).format("DD.MM.YYYY")
}
// short
export function FDS(v?: string|Date): string {
    return moment(v).format("DD.MM.YY")
}

// with hidden spaces
export function FDHBR(v?: string|Date): string {
    return moment(v).format("DD.\u200BMM.\u200BYYYY")
}

export function DateBetween(a: string | undefined, b: string | undefined): boolean {
    const now = new Date();
    const startDate = a ? PDS(a) : undefined;
    const endDate = b ? PDS(b) : undefined;

    if (!startDate && !endDate) {
        return false;
    } else if (!startDate && endDate && endDate <= now) {
        return false;
    } else if (!endDate && startDate && startDate <= now) {
        return true;
    } else if (startDate && endDate && startDate <= now && endDate >= now) {
        return true;
    }
    return false;
}

export function DateBetweenText(a: string | undefined, b: string | undefined): string {
    const now = new Date();
    const startDate = a ? PDS(a) : undefined;
    const endDate = b ? PDS(b) : undefined;

    // console.log('startDate: ', startDate)
    // console.log('endDate: ', endDate)

    if (!startDate && !endDate) {
        return 'missing_dates';
    } else if (!startDate && endDate && endDate <= now) {
        return 'closed';
    } else if (startDate && !endDate && startDate <= now) {
        return 'missing_end';
    } else if (startDate && endDate && startDate <= now && endDate >= now) {
        return 'open';
    } else if (startDate && endDate && startDate >= now ) {
        return 'soon';
    }
    return 'false';
}

export function FDX(v?: string): string|null {
    if (!v) {
        return null;
    }
    if (v?.startsWith('2999')) {
        return null;
    }
    return moment(v).format("DD.MM.YYYY")
}

export function FDXS(v?: string): string|null { // short
    if (!v) {
        return null;
    }
    if (v?.startsWith('2999')) {
        return null;
    }
    return moment(v).format("DD.MM.YY")
}

export function DS(v?: string|Date): string {
    return moment(v).format("YYYY-MM-DD")
}

export function DTS(v?: string|Date): string {
    return moment.utc(v).toISOString()
}


export const DateLabel: React.FC<IDateLabelProps> = ({value}) => {
    return <Label><>{moment(value).format('DD. MM. YYYY')}</>
    </Label>
}

export function FDT(v?: string|Date): string {
    return moment(v).format("DD.MM.YYYY HH:mm:ss")
}

// If you want to add hidden spaces (like FDHBR does)
export function FDTHBR(v?: string|Date): string {
    return moment(v).format("DD.\u200BMM.\u200BYYYY HH:\u200Bmm:\u200Bss")
}

// In Date.tsx, modify the DateInput component to properly check types
export const DateInput: React.FC<IDateLabelProps> = ({
                                                         value,
                                                         onChange,
                                                         minDate,
                                                         maxDate,
                                                         disabled = false,
                                                         required,
                                                         placeholderText = "Datum auswählen",
                                                         showTimeSelect = false,
                                                         timeFormat = 'HH:mm',
                                                         timeIntervals = 30,
                                                         dateFormat = 'dd.MM.yyyy HH:mm',
                                                         defaultHour = 8,
                                                         defaultMinute = 0
                                                     }) => {
    // Hard-coded times for 8:00-21:30 in 30 minute intervals
    const availableTimes = [
        "08:00", "08:30", "09:00", "09:30", "10:00", "10:30",
        "11:00", "11:30", "12:00", "12:30", "13:00", "13:30",
        "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
        "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
        "20:00", "20:30", "21:00", "21:30", "22:00",
    ];

    // Convert the time strings to Date objects
    const injectTimes = useMemo(() => {
        return availableTimes.map(timeStr => {
            const [hours, minutes] = timeStr.split(":").map(Number);
            const date = new Date();
            date.setHours(hours, minutes, 0, 0);
            return date;
        });
    }, [availableTimes]);

    // Create a custom input component that matches what DatePicker expects
    const CustomInputComponent = forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>(
        ({ value, onClick, onChange: onInputChange }, ref) => (
            <input
                ref={ref}
                className="form-control"
                value={value as string}
                onChange={onInputChange}
                onClick={onClick}
                placeholder={showTimeSelect ? "DD.MM.YYYY HH:MM" : "DD.MM.YYYY"}
                disabled={disabled}
            />
        )
    );

    // Create a handler that matches DatePicker's expected signature
    const handleDateChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined) => {
        // Handle default hour for new date selections
        if (date && defaultHour !== undefined && onChange) {
            const isNewDate = !value ||
                !(value instanceof Date) ||
                date.getDate() !== value.getDate() ||
                date.getMonth() !== value.getMonth() ||
                date.getFullYear() !== value.getFullYear();

            if (isNewDate) {
                // If it's a new date selection, apply default time
                const newDate = new Date(date);
                newDate.setHours(defaultHour, defaultMinute, 0, 0);
                onChange(newDate);
                return;
            }
        }

        // Standard onChange handling
        if (onChange) {
            onChange(date);
        }
    };

    return (
        <div className={styles.datepickerContainer}>
            <DatePicker
                selected={value}
                onChange={handleDateChange}
                showYearDropdown
                locale={de}
                dateFormat={dateFormat}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                required={required}
                placeholderText={placeholderText}
                customInput={<CustomInputComponent />}
                todayButton="Heute"
                calendarClassName="datepicker-calendar"
                showTimeSelect={showTimeSelect}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                includeTimes={showTimeSelect ? injectTimes : undefined}
            />
        </div>
    );
};

function generateFormattedTimestamp(format: string): string {
    return moment(new Date()).format(format);
}


export function DSPg(v?: string|Date): string {
    if (!v) return '';
    const d = new Date(v);
    // Get local date components
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/**
 * Formats a date and time from separate fields into a localized string
 * Treats the input as local time, not UTC
 *
 * @param dateString - Date in format "YYYY-MM-DD"
 * @param timeString - Time in format "HH:MM:SS"
 * @returns Formatted date and time string in local format
 */
export const FormatMatchDate = (dateString: string, timeString: string) => {
    if (!dateString) return '';

    // Combine date and time and ensure it's treated as local time
    // The format will be "YYYY-MM-DDTHH:MM:SS" (ISO format without the Z suffix)
    const localDateTimeString = `${dateString}T${timeString || '00:00:00'}`;

    // Create a Date object - since we're not appending 'Z',
    // it will be interpreted as local time, not UTC
    const date = new Date(localDateTimeString);

    // Check if date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', localDateTimeString);
        return dateString; // Return original date string if invalid
    }

    // Format the date using toLocaleDateString
    const dateFormatted = date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    // Format the time using toLocaleTimeString if time is provided
    if (timeString) {
        const timeFormatted = date.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit'
        });

        // Return both date and time
        return `${dateFormatted} ${timeFormatted}`;
    }

    // Return only date if no time was provided
    return dateFormatted;
};
//export const VERSIONDATE: string = moment(new Date()).format('YYMM.DD.HHmm');

export function FDTNS(v?: string|Date): string {
    // Format date and time without seconds (NS = No Seconds)
    return moment(v).format("DD.MM.YYYY HH:mm");
}