import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import classNames from "classnames";
import {IPageLinkProps} from "../../page.props";
import styles from './league.module.scss'
import {useParamId, useParamOrg, useParamOrgMother, useParamRole} from "../../useParam";
import {Loading} from "../../foundation/Loading";
import { LeagueService } from "../../../api/league";
import { ILeagueTeamBegeg } from "../../../api/models/LeagueModel";
import {DateBetweenText, FDT, FDS, FDTHBR, FormatMatchDate, Input, Item, Select} from "../../form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
/*
SELECT * FROM bl_begeg where id in (2803,2808,2809)
ORDER BY id ASC
* */


const League: React.FC<IPageLinkProps> = ({user, gotoUrl, refresh}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [matches, setMatches] = useState<ILeagueTeamBegeg[]>([]);
    const [search, setSearch] = useState<string>('');
    const [selectedLeagueId, setSelectedLeagueId] = useState<number>(0); // 0 means show all leagues
    const [leagueOptions, setLeagueOptions] = useState<{bl_id: number, liga: string}[]>([]);

    const id = useParamId();
    const role = useParamRole(user);
    const orgId = useParamOrg();
    console.log('orgId:', orgId)

    const [selectedLeague] = useState<number>(orgId); // Keep this for API call
    const [selectedSeason] = useState<number>(2025); // Default to season 2024

    console.log('selectedLeague:', selectedLeague);
    console.log('selectedSeason:', selectedSeason);
    console.log('role.orgId:', role.orgId);

    const filterLength = 300;
    const orgMotherId = useParamOrgMother(user);
    const orgIdToUse = !orgMotherId || orgMotherId === 0 ? orgId : orgMotherId;

    useEffect(() => {
        setLoading(true);
        if(role != null) {
            // Keep the original API call
            LeagueService.begegAll(orgIdToUse, selectedLeague, selectedSeason).then((m) => {
                setMatches(m);

                // Extract unique leagues for the dropdown
                const uniqueLeagues = Array.from(
                    new Map(
                        m.map(match => [match.bl_id, { bl_id: match.bl_id, liga: match.liga }])
                    ).values()
                ).filter(league => league.bl_id && league.liga);

                setLeagueOptions(uniqueLeagues);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role, selectedLeague, selectedSeason])

    // Format league options for the Select component
    const selectOptions = useMemo(() => {
        const allOption = { label: 'Alle Ligen', value: 0 };

        // Sort league options alphabetically by liga name
        const leagueOpts = leagueOptions
            .map(league => ({
                label: league.liga,
                value: league.bl_id
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        return [allOption, ...leagueOpts];
    }, [leagueOptions]);


    const filter = useMemo(() => {
        // First get the source data
        const source = matches;

        // Apply league filtering
        const leagueFiltered = selectedLeagueId === 0
            ? source // Show all leagues
            : source.filter(match => match.bl_id === selectedLeagueId);

        // Then apply search filtering
        return search.length > 0 ? leagueFiltered.filter(x =>
            x.teamname_h?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.teamname_a?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.liga?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.ort?.toLowerCase()?.includes(search?.toLowerCase())
        ) : leagueFiltered;
    }, [matches, search, selectedLeagueId]);

    const handleLeagueChange = (value: any) => {
        setSelectedLeagueId(value);
    };

    const goto = (pcId: number) => {
        navigate(`${gotoUrl}/${pcId}`);
    };


    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading/>}
                {/*<ItemNav*/}
                {/*    label="Neuen Kampf erstellen"*/}
                {/*    icon={faAdd}*/}
                {/*    onClick={() => goto(0)}*/}
                {/*/>*/}
                <Item type="full" className={styles.search}>
                    <Input
                        onChange={setSearch}
                        value={search}
                        className={styles.name}
                        placeholder="Suche nach Teams, Liga oder Ort..."
                    />
                    <Select
                        className={styles.dropdown}
                        value={selectedLeagueId}
                        onChange={handleLeagueChange}
                        options={selectOptions}
                        type="number"
                        placeholder="Alle Ligen"
                    />
                </Item>
                {!loading && (filter?.length > 0 ? (
                    <>
                        {filter.slice(0, filterLength).map((match) => (
                            <ItemNav
                                key={match.id}
                                onClick={() => goto(match.id)}
                                className={classNames(styles.content)}
                                selected={id === match.id}
                            >
                                <div className={styles.entry}>
                                    <div className={styles.name}>
                                        <div className={styles.leagueName}>
                                            {match.liga} - Runde {match.runde} {match.playoff && `(${match.playoff})`}
                                        </div>
                                        <div className={styles.matchTitle}>
                                            {match.teamname_h} vs {match.teamname_a}
                                        </div>
                                        <div className={styles.score}>
                                            {(match.erg_siege_h !== undefined && match.erg_siege_a !== undefined)
                                                ? `${match.erg_siege_h}:${match.erg_siege_a} `
                                                : "–:– "}
                                            ({(match.erg_ubw_h !== undefined && match.erg_ubw_a !== undefined)
                                            ? `${match.erg_ubw_h}:${match.erg_ubw_a}`
                                            : "–:–"})
                                        </div>
                                        <div className={styles.venue}>
                                            {match.ort}
                                        </div>
                                        <div className={styles.date}>
                                            {/*{formatMatchDate(match.kampfbeginn)}*/}
                                            {FormatMatchDate(match.kampfbeginn, match.uhrzeit)} / Match: {match.id}
                                        </div>
                                    </div>
                                </div>
                            </ItemNav>
                        ))}
                        {filter.length > filterLength && (
                            <Item>Only showing first {filterLength} results</Item>
                        )}
                    </>
                ) : <Item type="list" label="Keine Kämpfe gefunden!" />)}
            </>
        </ItemNavGroup>
    </>
}

export default League;