import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import 'moment/locale/de';
import {IMeeting, IMeetingCandidatesAll, IUserModel} from "../../../api/models";
import { Button } from "../../form";
import styles from './event_detail.module.scss';

import judoLogo from '../../../theme/editions/judoaustria/images/judo_logo.png';
import {useParamId, useParamRole} from "../../useParam";

// Format date for PDF
const formatPdfDate = (dateString: string): string => {
    if (!dateString) return '';
    return moment(dateString).format('DD.MM.YYYY');
};

// Define interface for EventSummaryPDF props
interface EventSummaryPDFProps {
    meeting: IMeeting | null;
    user: IUserModel;
    candidates: IMeetingCandidatesAll[];
    isAbhaken?: boolean;
}

// Format gender from code to text
const formatGender = (gender: string): string => {
    const genderMap: Record<string, string> = {
        'm': 'männlich',
        'w': 'weiblich',
        'd': 'divers',
        'x': 'keine Angabe'
    };
    return genderMap[gender] || gender;
};

// Standard order for age categories (without gender suffix)
const akBaseOrder = ['U08', 'U10', 'U12', 'U14', 'U16', 'U18', 'U21', 'U23', 'SEN'];

// Define styles for PDF
const pdfStyles = StyleSheet.create({
    page: {
        padding: 40,
        paddingTop: 60,
        paddingLeft: 65, // Increase left padding
        fontSize: 12,
        position: 'relative',
    },
    header: {
        fontSize: 16,
        marginTop: 15,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    subHeader: {
        fontSize: 12,
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    summary: {
        marginBottom: 20,
        padding: 10,
        borderBottom: '1pt solid #ccc',
    },
    summaryRow: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    summaryLabel: {
        width: '30%',
        fontWeight: 'bold',
    },
    summaryValue: {
        width: '70%',
    },
    roleGroup: {
        marginBottom: 15,
    },
    roleHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        //backgroundColor: '#f0f0f0',
        padding: 5,
        marginBottom: 5,
        marginTop: 10,
    },
    ageGroupHeader: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'white',
        padding: 5,
        backgroundColor: '#626262',
        marginTop: 20,
    },
    weightGroupHeader: {
        fontSize: 10,
        fontWeight: 'bold',
        padding: 5,
        //backgroundColor: '#f0f0f0',
        marginTop: 10,
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        padding: 5,
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        padding: 5,
    },
    tableRowEven: {
        backgroundColor: '#f1f1f1',
    },
    tableRowOdd: {
        backgroundColor: '#ffffff',
    },
    secondLine: {
        flexDirection: 'row',
        fontSize: 10,
        color: '#444',
        paddingLeft: 45, // Indent second line
    },
    // Columns for main row
    jamaIdCol: {
        width: '12%',
    },
    nameCol: {
        width: '31%',
    },
    firstnameCol: {
        width: '18%',
    },
    dateCol: {
        width: '16%',
    },
    genderCol: {
        width: '10%',
        textAlign: 'center',
    },
    iocCol: {
        width: '15%',
    },
    judoCardCol: {
        width: '7%',
    },
    // Second line columns
    orgCol: {
        width: '45%',
    },
    nationCheckedCol: {
        width: '30%',
    },
    categoriesCol: {
        width: '25%',
    },
    // Checkbox styles for abhaken mode - moved to right side and made square
    checkboxCol: {
        width: '8%',
    },
    checkbox: {
        width: 15,
        height: 15,
        borderWidth: 1,
        borderColor: '#000',
        marginLeft: 'auto', // Push to right side
    },
    pageNumber: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        fontSize: 10,
        color: '#666',
    },
    fileName: {
        position: 'absolute',
        bottom: 20,
        left: 45,
        fontSize: 8,
        color: '#666',
    },
    logo: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: '70px',
    },
});

// Define interfaces for grouped data
interface GkGroups {
    [key: string]: IMeetingCandidatesAll[];
}

interface AkGroups {
    [key: string]: GkGroups;
}

interface GroupedParticipants {
    [role: string]: IMeetingCandidatesAll[] | AkGroups;
}

// Helper function to extract base AK without gender suffix
const getBaseAK = (ak: string): string => {
    // Extract base AK without gender suffix (like U21 from U21M)
    if (!ak) return 'Unbekannt';

    // Match pattern like "U21M", "U10F", "SENM", etc.
    const match = ak.match(/^(U\d+|SEN)[MFmf]?$/);
    if (match) {
        return match[1].toUpperCase(); // Return the base part (U21, U10, SEN)
    }

    return ak; // Return original if no match
};

// Helper function to compare age categories in the standard order
const compareAK = (a: string, b: string): number => {
    const baseA = getBaseAK(a);
    const baseB = getBaseAK(b);

    const indexA = akBaseOrder.indexOf(baseA);
    const indexB = akBaseOrder.indexOf(baseB);

    // If both categories are in our order list
    if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
    }

    // If only one is in our order list
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither is in our list, use alphabetical order
    return a.localeCompare(b);
};

// Helper function to compare weight categories
const compareGK = (a: string, b: string): number => {
    // Extract numeric part if it exists (like "66" from "U66")
    const numA = a ? parseInt(a.replace(/[^\d]/g, '')) : 0;
    const numB = b ? parseInt(b.replace(/[^\d]/g, '')) : 0;

    // If both have numeric parts, compare them
    if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
    }

    // Otherwise use alphabetical order
    return (a || '').localeCompare(b || '');
};

// Group and sort participants
function groupParticipants(candidates: IMeetingCandidatesAll[], isAbhaken: boolean = false): GroupedParticipants {
    // Filter candidates if isAbhaken is true (show only Judoka)
    const filteredCandidates = isAbhaken
        ? candidates.filter(candidate => (candidate.role || '').toLowerCase() === 'judoka')
        : candidates;

    // First group by role
    const roleGroups: Record<string, IMeetingCandidatesAll[]> = {};

    filteredCandidates.forEach(candidate => {
        const role = candidate.role || 'Unbekannt';
        if (!roleGroups[role]) {
            roleGroups[role] = [];
        }
        roleGroups[role].push(candidate);
    });

    // For each role group, sort or sub-group as needed
    const sortedGroups: GroupedParticipants = {};

    Object.keys(roleGroups).forEach(role => {
        if (role.toLowerCase() === 'judoka') {
            // For Judoka, first group by AK
            const akGroups: AkGroups = {};

            roleGroups[role].forEach(judoka => {
                const ak = judoka.AK || 'Keine AK';
                if (!akGroups[ak]) {
                    akGroups[ak] = {};
                }

                // Then group by GK within each AK
                const gk = judoka.GK || 'Keine GK';
                if (!akGroups[ak][gk]) {
                    akGroups[ak][gk] = [];
                }

                akGroups[ak][gk].push(judoka);
            });

            // Sort each GK group by familyName
            const sortedAkGroups: AkGroups = {};

            // Sort AK groups using our custom order
            Object.keys(akGroups).sort(compareAK).forEach(ak => {
                sortedAkGroups[ak] = {};

                // Sort GK groups within each AK
                Object.keys(akGroups[ak]).sort(compareGK).forEach(gk => {
                    sortedAkGroups[ak][gk] = akGroups[ak][gk].sort((a, b) =>
                        (a.familyName || '').localeCompare(b.familyName || '')
                    );
                });
            });

            sortedGroups[role] = sortedAkGroups;
        } else {
            // For other roles, just sort by familyName
            sortedGroups[role] = roleGroups[role].sort((a, b) =>
                (a.familyName || '').localeCompare(b.familyName || '')
            );
        }
    });

    return sortedGroups;
}

// Create a PDF document component for event summary
export const EventSummaryPDF: React.FC<EventSummaryPDFProps> = ({ meeting, user, candidates, isAbhaken = false }) => {
    // Group and sort the participants
    const groupedParticipants = groupParticipants(candidates, isAbhaken);
    const id = useParamId();
    const role = useParamRole(user);
    console.log('++id:', id);
    console.log('++user:', user);
    console.log('++Role from useParamRole:', role);
    // Generate a meaningful filename
    const fileName = `teilnehmer-event-${meeting?.id}-${isAbhaken ? 'abhaken-' : ''}${new Date().toISOString().split('T')[0]}.pdf`;

    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                {/* Logo in the top right corner */}
                <Image src={judoLogo} style={pdfStyles.logo} fixed />

                {/* Header */}
                <Text style={pdfStyles.header}>
                    Event #{meeting?.id} - {meeting?.name} - {isAbhaken ? 'Liste zum Abhaken' : 'Teilnehmerliste'}
                </Text>
                {/*<Text style={pdfStyles.subHeader}>*/}
                {/*    Verein: {role?.orgName}*/}
                {/*</Text>*/}
                {/* Event summary */}
                <View style={pdfStyles.summary}>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Veranstalter:</Text>
                        <Text style={pdfStyles.summaryValue}>{meeting?.name}</Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Zeitraum:</Text>
                        <Text style={pdfStyles.summaryValue}>
                            {formatPdfDate(meeting?.startdate || '')} - {formatPdfDate(meeting?.enddate || '')}
                        </Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Ort:</Text>
                        <Text style={pdfStyles.summaryValue}>{meeting?.param?.ORT || ''}</Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Teilnehmer:</Text>
                        <Text style={pdfStyles.summaryValue}>{isAbhaken ?
                            candidates.filter(c => (c.role || '').toLowerCase() === 'judoka').length :
                            candidates.length}</Text>
                    </View>
                </View>

                {/* Role groups */}
                {Object.keys(groupedParticipants).sort().map(role => {
                    if (role.toLowerCase() === 'judoka') {
                        // For Judoka, we have nested AK and GK groups
                        const akGroups = groupedParticipants[role] as AkGroups;
                        const totalJudokas = Object.values(akGroups).reduce((akSum, gkGroups) =>
                            akSum + Object.values(gkGroups).reduce((gkSum, group) => gkSum + group.length, 0), 0);

                        return (
                            <View key={role} style={pdfStyles.roleGroup}>
                                <Text style={pdfStyles.roleHeader}>{role} ({totalJudokas})</Text>

                                {/* For Judoka role, we render by age groups then weight groups */}
                                {Object.keys(akGroups).sort(compareAK).map(ak => {
                                    const gkGroups = akGroups[ak];
                                    const akTotal = Object.values(gkGroups).reduce((sum, group) => sum + group.length, 0);

                                    return (
                                        <View key={ak}>
                                            <Text style={pdfStyles.ageGroupHeader}>
                                                Altersklasse: {ak} ({akTotal})
                                            </Text>

                                            {Object.keys(gkGroups).sort(compareGK).map(gk => {
                                                const participants = gkGroups[gk];

                                                return (
                                                    <View key={`${ak}-${gk}`}>
                                                        <Text style={pdfStyles.weightGroupHeader}>
                                                            Gewichtsklasse: {gk} ({participants.length})
                                                        </Text>

                                                        <View style={pdfStyles.table}>
                                                            {/* Table header */}
                                                            <View style={pdfStyles.tableHeader}>
                                                                <Text style={pdfStyles.jamaIdCol}>ID</Text>
                                                                <Text style={pdfStyles.nameCol}>Nachname</Text>
                                                                <Text style={pdfStyles.firstnameCol}>Vorname</Text>
                                                                <Text style={pdfStyles.dateCol}>Geb.</Text>
                                                                <Text style={pdfStyles.genderCol}>m/w</Text>
                                                                <Text style={pdfStyles.iocCol}>Nat/Lic</Text>
                                                                {/*<Text style={pdfStyles.judoCardCol}>JC</Text>*/}
                                                                {isAbhaken && <Text style={pdfStyles.checkboxCol}></Text>}
                                                            </View>

                                                            {/* Table rows */}
                                                            {participants.map((participant: IMeetingCandidatesAll, index: number) => (
                                                                <View key={`${participant.jamaId}-${index}`}>
                                                                    {/* Main row */}
                                                                    <View style={[
                                                                        pdfStyles.tableRow,
                                                                        index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                                                    ]}>
                                                                        <Text style={pdfStyles.jamaIdCol}>{participant.jamaId}</Text>
                                                                        <Text style={pdfStyles.nameCol}>{participant.familyName || ''}</Text>
                                                                        <Text style={pdfStyles.firstnameCol}>{participant.firstName || ''}</Text>
                                                                        <Text style={pdfStyles.dateCol}>{formatPdfDate(participant.born || '')}</Text>
                                                                        <Text style={pdfStyles.genderCol}>{participant.gender || ''}</Text>
                                                                        <Text style={pdfStyles.iocCol}>
                                                                            {participant.ioc || ''}{participant.licenseB !== '--' ? '/' + participant.licenseB : ''}
                                                                        </Text>
                                                                        {/*<Text style={pdfStyles.judoCardCol}>{participant.judoCard || ''}</Text>*/}
                                                                        {isAbhaken && <View style={pdfStyles.checkboxCol}>
                                                                            <View style={pdfStyles.checkbox} />
                                                                        </View>}
                                                                    </View>

                                                                    {/* Second line with organization, nation checked, and categories - hidden for abhaken mode */}
                                                                    {!isAbhaken && (
                                                                        <View style={[
                                                                            pdfStyles.secondLine,
                                                                            index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                                                        ]}>
                                                                            <Text style={pdfStyles.orgCol}>{participant.orgName || ''}</Text>
                                                                            <Text style={pdfStyles.nationCheckedCol}>
                                                                                {participant.nationChecked ? `Nation OK: ${formatPdfDate(participant.nationChecked)}` : ''}
                                                                            </Text>
                                                                            <Text style={pdfStyles.categoriesCol}>
                                                                                {participant.AK ? `${participant.AK}` : ''}
                                                                                {participant.GK ? ` / ${participant.GK}` : ''}
                                                                            </Text>
                                                                        </View>
                                                                    )}
                                                                </View>
                                                            ))}
                                                        </View>
                                                    </View>
                                                );
                                            })}
                                        </View>
                                    );
                                })}
                            </View>
                        );
                    } else if (!isAbhaken) {
                        // For other roles, we just have a simple sorted list
                        // These are only shown when isAbhaken is false
                        const participants = groupedParticipants[role] as IMeetingCandidatesAll[];

                        return (
                            <View key={role} style={pdfStyles.roleGroup}>
                                <Text style={pdfStyles.roleHeader}>{role} ({participants.length})</Text>

                                <View style={pdfStyles.table}>
                                    {/* Table header */}
                                    <View style={pdfStyles.tableHeader}>
                                        <Text style={pdfStyles.jamaIdCol}>ID</Text>
                                        <Text style={pdfStyles.nameCol}>Nachname</Text>
                                        <Text style={pdfStyles.firstnameCol}>Vorname</Text>
                                        <Text style={pdfStyles.dateCol}>Geb.</Text>
                                        <Text style={pdfStyles.genderCol}>m/w</Text>
                                        <Text style={pdfStyles.iocCol}>Nat/Liz</Text>
                                        {/*<Text style={pdfStyles.judoCardCol}>JC</Text>*/}
                                    </View>

                                    {/* Table rows */}
                                    {participants.map((participant: IMeetingCandidatesAll, index: number) => (
                                        <View key={`${participant.jamaId}-${index}`}>
                                            {/* Main row */}
                                            <View style={[
                                                pdfStyles.tableRow,
                                                index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                            ]}>
                                                <Text style={pdfStyles.jamaIdCol}>{participant.jamaId}</Text>
                                                <Text style={pdfStyles.nameCol}>{participant.familyName || ''}</Text>
                                                <Text style={pdfStyles.firstnameCol}>{participant.firstName || ''}</Text>
                                                <Text style={pdfStyles.dateCol}>{formatPdfDate(participant.born || '')}</Text>
                                                <Text style={pdfStyles.genderCol}>{participant.gender || ''}</Text>
                                                <Text style={pdfStyles.iocCol}>
                                                    {participant.ioc || ''}{participant.licenseB !== '--' ? '/' + participant.licenseB : ''}
                                                </Text>
                                                {/*<Text style={pdfStyles.judoCardCol}>{participant.judoCard || ''}</Text>*/}
                                            </View>

                                            {/* Second line with organization and nation checked */}
                                            <View style={[
                                                pdfStyles.secondLine,
                                                index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                            ]}>
                                                <Text style={pdfStyles.orgCol}>{participant.orgName || ''}</Text>
                                                <Text style={pdfStyles.nationCheckedCol}>
                                                    {participant.nationChecked ? `Nation OK: ${formatPdfDate(participant.nationChecked)}` : ''}
                                                </Text>
                                                <Text style={pdfStyles.categoriesCol}>
                                                    {participant.ausbDesc || ''}
                                                </Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        );
                    } else {
                        // For other roles when isAbhaken is true, return null (don't render)
                        return null;
                    }
                })}

                {/* Filename at the bottom-left */}
                <Text style={pdfStyles.fileName} fixed>
                    {fileName}
                </Text>

                {/* Page number at the bottom-right */}
                <Text
                    style={pdfStyles.pageNumber}
                    render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
};

// Define interface for EventSummaryPDFViewer props
interface EventSummaryPDFViewerProps {
    meeting: IMeeting | null;
    user: IUserModel;
    candidates: IMeetingCandidatesAll[];
    onClose: () => void;
    isAbhaken?: boolean;
}

// PDF Viewer Component with modal
export const EventSummaryPDFViewer: React.FC<EventSummaryPDFViewerProps> = ({ meeting, user, candidates, onClose, isAbhaken = false }) => {
    return (
        <div className={styles.pdfViewer}>
            <div className={styles.pdfViewerHeader}>
                <h3>PDF Vorschau - Event #{meeting?.id} {meeting?.name} {isAbhaken ? '(Liste zum Abhaken)' : ''}</h3>
                <Button
                    className={styles.closePdfButton}
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faRemove} />Schließen
                </Button>
            </div>
            <div className={styles.pdfViewerContent}>
                <PDFViewer width="100%" height="100%">
                    <EventSummaryPDF
                        meeting={meeting}
                        user={user}
                        candidates={candidates}
                        isAbhaken={isAbhaken}
                    />
                </PDFViewer>
            </div>
        </div>
    );
};