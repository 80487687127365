import {ITrainerJudoCard, ITrainerJudocardFilter} from "./models/TrainerJudoCard";
import {HttpService} from "./http";
import {Constants} from "./constants";

const GET_TRAINER_JUDOCARDS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/judocard/:year?limit=&offset=`;

/**
 * Interface representing the response structure from the judocards API with added hasMore property
 */
export interface IJudocardsResponse {
    total: number;
    offset: number;
    limit: number;
    members: ITrainerJudoCard[];
    hasMore?: boolean; // Added proper  ty to simplify pagination checks
}

/**
 * Service class for retrieving trainer judocards.
 */
export class JudocardService {
    /**
     * Retrieves trainer judocards based on the given parameters.
     *
     * @param {number} org - The organization ID.
     * @param {ITrainerJudocardFilter} [filter] - Optional filter object (not used in current implementation).
     * @param {number} [offset=0] - The number of items to skip before starting to return items.
     * @param {number} [limit=50] - The maximum number of items to return.
     * @param {number} [year] - The year for judocards. Defaults to current year if not specified.
     *
     * @returns {Promise<ITrainerJudoCard[]>} - A promise that resolves to an array of trainer judocards.
     */
    public static async getTrainerJudocards(
        org: number,
        filter?: ITrainerJudocardFilter,
        offset: number = 0,
        limit: number = 50,
        year?: number
    ): Promise<ITrainerJudoCard[]> {
        const response = await this.getTrainerJudocardsWithPagination(org, filter, offset, limit, year);
        return response.members;
    }

    /**
     * Retrieves trainer judocards with full pagination information.
     *
     * @param {number} org - The organization ID.
     * @param {ITrainerJudocardFilter} [filter] - Optional filter object (not used in current implementation).
     * @param {number} [offset=0] - The number of items to skip before starting to return items.
     * @param {number} [limit=50] - The maximum number of items to return.
     * @param {number} [year] - The year for judocards. Defaults to current year if not specified.
     *
     * @returns {Promise<IJudocardsResponse>} - A promise that resolves to the judocards response with added hasMore property.
     */
    public static async getTrainerJudocardsWithPagination(
        org: number,
        filter?: ITrainerJudocardFilter,
        offset: number = 0,
        limit: number = 50,
        year?: number
    ): Promise<IJudocardsResponse> {
        // Get the current year if not specified
        const cardYear = year || new Date().getFullYear();

        // Update URL format with year, limit and offset specified in the base URL
        const url = GET_TRAINER_JUDOCARDS
            .replace(':org', org.toString())
            .replace(':year', cardYear.toString())
            .replace('?limit=', `?limit=${limit}`)
            .replace('&offset=', `&offset=${offset}`);

        const finalUrl = `${url}`;

        const response = await HttpService.get<IJudocardsResponse>(finalUrl, true, true);

        // If no response, return default structure
        if (!response) {
            return {
                total: 0,
                offset: offset,
                limit: limit,
                members: [],
                hasMore: false
            };
        }

        // Add hasMore property to the response
        return {
            ...response,
            hasMore: (response.offset + response.limit) < response.total
        };
    }

    /**
     * Retrieves all trainer judocards for an organization by handling pagination automatically.
     * Use this method when you need to retrieve the complete set of judocards regardless of count.
     *
     * @param {number} org - The organization ID.
     * @param {number} [batchSize=100] - The number of items to fetch in each batch.
     * @param {number} [year] - The year for judocards. Defaults to current year if not specified.
     * @returns {Promise<ITrainerJudoCard[]>} - A promise that resolves to the complete array of trainer judocards.
     */
    public static async getAllTrainerJudocards(
        org: number,
        batchSize: number = 100,
        year?: number
    ): Promise<ITrainerJudoCard[]> {
        let allJudocards: ITrainerJudoCard[] = [];
        let offset = 0;
        let hasMore = true;

        // Fetch data in batches until we have all records
        while (hasMore) {
            const result = await this.getTrainerJudocardsWithPagination(org, undefined, offset, batchSize, year);
            allJudocards = [...allJudocards, ...result.members];

            // Update pagination variables for next iteration
            offset += batchSize;
            hasMore = result.hasMore || false;

            // Safety check to prevent infinite loops
            if (result.members.length === 0) {
                hasMore = false;
            }
        }

        return allJudocards;
    }
}