// src/components/foundation/spinner.tsx
import React from 'react';
import './spinner.css'; // We'll create this CSS file next

interface SpinnerProps {
    /**
     * Size of the spinner
     * @default "medium"
     */
    size?: 'small' | 'medium' | 'large';

    /**
     * Additional CSS class names
     */
    className?: string;

    /**
     * Text to display below the spinner
     */
    text?: string;
}

/**
 * Spinner component for indicating loading states
 */
export const Spinner: React.FC<SpinnerProps> = ({
                                                    size = 'medium',
                                                    className = '',
                                                    text
                                                }) => {
    const sizeClasses = {
        small: 'spinner-small',
        medium: 'spinner-medium',
        large: 'spinner-large'
    };

    return (
        <div className={`spinner-container ${className}`}>
            <div className={`spinner ${sizeClasses[size]}`}>
                <div className="spinner-inner"></div>
            </div>
            {text && <div className="spinner-text">{text}</div>}
        </div>
    );
};