    import {Constants} from "./constants";
    import {HttpService} from "./http";
    import {AuthService} from "./auth";
    import {CacheService} from "./cache";
    import {ILeagueTeamEinzelBegeg, ILeagueTeamBegeg, ILeagueReferee } from "./models/LeagueModel";


    // API Endpoints
    // const GET_LEAGUE_EINZEL = `${Constants.JAMA_SERVER_API_V2}/admin/:org/league/team/einzel/:id`;
    // const GET_LEAGUE_BEGEG = `${Constants.JAMA_SERVER_API_V2}/admin/:org/league/team/begeg/:id/:saison`;
    // const GET_LEAGUE_REFEREES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/league/referees`;

    interface UpdateBlBegegnungPayload {
        kampfrichter1_mg_id?: number | null;
        kampfrichter2_mg_id?: number | null;
        kampfrichter3_mg_id?: number | null;
        decisionMatchWinner?: 'home' | 'away' | null; // Existing simple version
        erg_siege_h?: number;
        erg_siege_a?: number;
        erg_ubw_h?: number;
        erg_ubw_a?: number;
        erg_shido_h?: number;
        erg_shido_a?: number;
        erg_punkte_h?: number;
        erg_punkte_a?: number;
    }

    export class LeagueService {

        public static async begegAll(org: number, orgId: number, saison: number = 2025): Promise<ILeagueTeamBegeg[]> {
            // await AuthService.refreshJwt();
            // Construct URL directly
            // orgId = 1;
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/team/begeg/${orgId}/${saison}`;
            const res = await HttpService.get<ILeagueTeamBegeg[]>(url, true, false);

            // Add pcId field to make it compatible with the existing UI
            if (res) {
                return res.map(item => ({
                    ...item,
                    pcId: item.id // Using the match ID as pcId for now
                }));
            }
            return [];
        }

        public static async begegOne(org: number, orgId: number, saison: number = 2025, begegId: number): Promise<ILeagueTeamBegeg | null> {
            // await AuthService.refreshJwt();
            // Construct URL directly
            // orgId = 5                           ;
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/team/begeg/${orgId}/${saison}/${begegId}`;
            const res = await HttpService.get<ILeagueTeamBegeg>(url, true, false);

            // Add pcId field to make it compatible with the existing UI
            if (res) {
                return {
                    ...res,
                    pcId: res.id // Using the match ID as pcId for now
                };
            }
            return null;
        }

        public static async getEinzelBegegnung(org: number, matchId: number): Promise<ILeagueTeamEinzelBegeg                    []> {
            // await AuthService.refreshJwt();
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/team/einzel/${matchId}`;
            console.log('Fetching individual matches with URL:', url);
            const response = await HttpService.get<ILeagueTeamEinzelBegeg[]>(url, true, false);

            if (!response || response.length === 0) {
                console.log('No individual matches found');
                return [];
            }

            console.log(`Found ${response.length} individual matches`);
            return response;
        }

        public static async getReferees(org: number): Promise<ILeagueReferee[]> {
            // await AuthService.refreshJwt();
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/referees`;
            console.log('Fetching referees with URL:', url);
            const response = await HttpService.get<ILeagueReferee[]>(url, true, false);

            if (!response || response.length === 0) {
                console.log('No referees found');
                return [];
            }

            console.log(`Found ${response.length} referees`);
            return response;
        }

        // Updated function in league.ts that can handle both referee data and match results
        // Updated function in league.ts that handles connection errors properly
        public static async updateBlBegegnung(org: number, matchId: number, data: UpdateBlBegegnungPayload): Promise<boolean> {
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/team/begeg/${matchId}`;
            //  console.log(`Updating match data: ${url}`, data);
            if(data.erg_siege_h == 0 && data.erg_siege_a == 0 && data.erg_ubw_h==0 && data.erg_ubw_a == 0 && data.erg_shido_h == 0
                    && data.erg_shido_a && data.erg_punkte_h==1 && data.erg_punkte_a == 1) {
                  data.erg_punkte_h = 0;
                  data.erg_punkte_a = 0;
            }

            try {
                const response = await HttpService.patch(url, data);
                if (!response) {
                    console.error("API returned a falsy response");
                    throw new Error("API returned a falsy response");
                }
                return true;
            } catch (error) {
                console.error("Error updating match data:", error);
                // Re-throw the error so it can be caught by the caller (toaster)
                throw error;
            }
        }


        public static async updateEinzelbegegnung(org: number, matchId: number, matches: ILeagueTeamEinzelBegeg[]): Promise<boolean> {
            // await AuthService.refreshJwt();
            const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/league/team/einzel/${matchId}`;
            console.log('Updating individual matches with URL:', url);

            try {
                const response = await HttpService.patch(url, { matches });

                if (response) {
                    console.log('Successfully updated individual matches');
                    return true;
                }

                console.error('Failed to update individual matches');
                return false;
            } catch (error) {
                console.error('Error updating individual matches:', error);
                return false;
            }
        }
    }