// MemberCardsPDFStyles.ts
import { Style } from '@react-pdf/types';

// Type-safe PDF styles
export const pdfStyles: Record<string, Style> = {
    page: {
        flexDirection: 'row' as const,
        flexWrap: 'wrap' as const,
        backgroundColor: 'white',
        padding: 0,
        paddingTop: '10mm',
        paddingLeft: '20.0mm',
    },
    card: {
        width: '85mm',
        height: '55mm',
        margin: 0,
        border: '1pt solid gray',
        padding: 5,
        flexDirection: 'column' as const,
    },
    cardHeader: {
        backgroundColor: 'white',
        padding: 0,
        marginBottom: 5,
        width: '100%',
    },
    cardTitle: {
        color: '#b82025',
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'left' as const,
    },
    nameSection: {
        width: '100%',
        marginTop: 3,
        marginBottom: 8,
    },
    name: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left' as const,
    },
    contentContainer: {
        flex: 1,
        flexDirection: 'row' as const,
    },
    detailsContainer: {
        flex: 1,
        marginRight: 5,
        display: 'flex' as const,
        flexDirection: 'column' as const,
    },
    imageContainer: {
        width: '21mm',
        maxHeight: '27mm',
        alignItems: 'flex-end' as const,
    },
    memberImage: {
        width: '20mm',
        height: '27mm',
        border: '1pt solid silver',
        backgroundColor: 'silver',
        borderRadius: 3,
    },
    logoImage: {
        width: '20mm',
        height: '10mm',
        marginBottom: 2,
        objectFit: 'contain' as const,
    },
    infoRow: {
        flexDirection: 'row' as const,
        justifyContent: 'space-between' as const,
        marginTop: 2,
        marginBottom: 2,
    },
    label: {
        fontSize: 8,
        fontWeight: 'bold',
    },
    value: {
        fontSize: 8,
    },
    valueBelt: {
        height: '1rem',
        marginBottom: '1rem',
    },
    barcodeContainer: {
        marginTop: 'auto',
        width: '100%',
        paddingTop: 2,
    },
    footerText: {
        color: 'black',
        fontSize: 6,
        textAlign: 'center' as const,
    }
};